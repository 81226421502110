import { useRef } from "react";
import { useLocationParams } from "./hooks/useLocationParams";

function VideoPlayer() {
  const { autoplay, filename, height, loop, muted, width, controls } =
    useLocationParams();

  const ref: any = useRef(null);

  const url = `https://ik.imagekit.io/raqwvm0o0/${filename}`;

  window.onmessage = (event) => {
    if (!ref.current) return;
    if (event.data === "start") ref.current.play();
    else if (event.data === "stop") ref.current.pause();
  };

  return (
    <video
      ref={ref}
      src={url}
      width={width || "250"}
      height={height || "300"}
      style={{ objectFit: "cover" }}
      autoPlay={autoplay}
      loop={loop}
      muted={muted}
      controls={controls}
    />
  );
}

export default VideoPlayer;
