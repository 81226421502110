import { useLocation } from "react-router-dom";

export const useLocationParams = () => {
  const queryParams = new URLSearchParams(useLocation().search);

  const muted = queryParams.get("muted") === "true" ?? false;
  const loop = queryParams.get("loop") === "true" ?? false;
  const autoplay = queryParams.get("autoplay") === "true" ?? false;
  const controls = queryParams.get("controls") === "true" ?? false;
  const filename = queryParams.get("filename") ?? "";
  const height = queryParams.get("height") ?? "";
  const width = queryParams.get("width") ?? "";

  return {
    muted,
    loop,
    autoplay,
    filename,
    height,
    width,
    controls
  };
};
