import { BrowserRouter, Route, Routes } from "react-router-dom";
import VideoPlayerIframe from "./iframes/video-player-iframe/video-player-iframe";
import DefaultScreen from "./screens/default-screen/default-screen";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/video-player-iframe" Component={VideoPlayerIframe} />
        <Route path="/*" Component={DefaultScreen} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
